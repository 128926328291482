import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '@/api/lobby-game'
import { DISPLAY_TYPE, TITLE_GAME, EMPTY_CONTENT, SORT } from '~~/constants/lobby'
import { useGameStore } from '~~/store/game'
import { useLoading } from '~/composables/useLoading'
import { PAGE } from '~/constants/router'
import { PATH_GAME_URL } from '~/constants/path'
import { IItemCongGame, IProvider, ISortItem } from '~/types/lobby.type'
export const useGame = () => {
  interface IItemCongGameWithProvider extends IItemCongGame {
    url: string
    image_path: string
  }
  const { $device, $pinia, $axios } = useNuxtApp()
  const { LOBBY_CATEGORY, LIST_LOBBY_GAME } = useLobbyGameApi()
  const store = useGameStore($pinia)
  const route = useRoute()
  const router = useRouter()
  const { loading, load, isLoading } = useLoading()
  const { providerGames } = storeToRefs(store)
  const listGame = ref<IItemCongGame[]>([])
  const listProvider = ref<IProvider[]>([])
  const allObject = ref<IProvider>({
    code: 'all',
    name: 'Tất cả',
    url: '',
    image_path: `${PATH_GAME_URL}icon-all-mb.webp`
  })
  const currentProvider = ref<IProvider | null>(allObject.value)
  const currentSort = ref<ISortItem>(SORT[0] as ISortItem)
  const isShowCategoryProvider = ref<boolean>(true)
  const totalItemPerPage = ref<number>(0)
  const limit = 30
  const page = ref<number>(1)
  const totalItem = ref<number>(0)
  const isShowSort = ref<boolean>(false)
  const loadMoreLoading = ref<boolean>(true)
  const placeholderCount = ref<number>(0)
  const listCongGame = ref<IItemCongGame[]>([])

  const initData = async () => {
    page.value = 1
    loadMoreLoading.value = true
    const _query = { ...route.query }
    if (getItemBySort(_query.sort as string)) {
      currentSort.value = getItemBySort(_query.sort as string) ?? SORT[0]
      await fetchListGameSort(currentSort.value)
    } else {
      currentSort.value = SORT[0]
      if (route.params.type) {
        await fetchListGame()
      }
    }
    if (providerGames.value) {
      filterProvider(providerGames.value)
      currentProvider.value =
        listProvider.value.find((item: IProvider) => item?.code === _query.provider) || allObject.value
    }
  }

  function getItemBySort(value: string): ISortItem | undefined {
    return SORT.find((obj) => obj.typeUrl === value && obj.typeUrl !== '')
  }

  const handleFilterProvider = (item: IProvider) => {
    page.value = 1
    loadMoreLoading.value = true
    currentProvider.value = item
    const _query = { ...route.query }
    navigateTo({
      query: { ..._query, provider: item?.code }
    })
    setTimeout(() => {
      handleSort(SORT[0])
    }, 0)
  }

  const handleSort = (item: ISortItem) => {
    page.value = 1
    loadMoreLoading.value = true
    currentSort.value = item
    const _query = { ...route.query }
    if (currentSort.value.type && Number(currentSort.value.type) !== 0 && currentSort.value.type !== 'default') {
      fetchListGameSort(item)
      navigateTo({
        query: { ..._query, sort: currentSort.value?.typeUrl, provider: currentProvider.value?.code }
      })
    } else {
      fetchListGame()
      navigateTo({
        query: { ..._query, sort: currentSort.value?.typeUrl, provider: _query.provider }
      })
    }
  }

  const currentGameType = (): string => {
    const newPath = route.path.replace(/-/g, '_')
    const parts = newPath.split('/')
    let gameType = 'all'
    if (parts.length > 0) {
      gameType = parts[parts.length - 1]
    }
    return gameType
  }
  const filterProvider = (data: IItemCongGame[]) => {
    const type = route?.params?.type
    const filteredItem = data.find((item: IItemCongGame) => item?.alias === type)
    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item: IProvider) => item?.code === 'all')
    if (!isAllExists) {
      listProvider.value.unshift(allObject.value)
    }
    if (listProvider.value.length > 2) {
      isShowCategoryProvider.value = true
    } else {
      isShowCategoryProvider.value = false
    }
  }
  const getBackgroundFromPath = (): string => {
    return 'defaultBackground'
  }

  const fetchGames = (queryUrl: string) => {
    return $axios.get(`${LIST_LOBBY_GAME}?${queryUrl}`)
  }

  const fetchListGame = async ($state?: any) => {
    const gameType = route?.params?.type
    totalItem.value = 0
    loading()
    const _query = { ...route.query }
    const partnerProvider = typeof _query?.provider === 'string' ? _query.provider.toLowerCase() : ''
    let queryUrl = ''
    if (partnerProvider && partnerProvider !== 'all') {
      queryUrl = queryString.stringify({
        path_alias: gameType,
        provider_code: partnerProvider,
        limit,
        page: page.value
      })
    } else {
      queryUrl = queryString.stringify({
        path_alias: gameType,
        limit,
        page: page.value
      })
    }

    try {
      const { data: response } = await fetchGames(queryUrl)
      if (response.status === 'OK') {
        if (page.value > 1) {
          listGame.value = listGame.value.concat(response.data?.items)
        } else {
          listGame.value = response.data?.items
        }
        totalItem.value = response.data?.total
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        store.setGameLists(listGame.value)
        if (totalItem.value < 10) {
          isShowSort.value = false
        } else {
          isShowSort.value = true
        }
        if (response.data.items?.length < limit && $state) {
          $state?.complete()
        } else if ($state) {
          $state?.loaded()
        }
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
    load()
  }
  const handleChangeCategoryMenu = (item: IItemCongGame) => {
    if (item?.alias || item?.link) {
      navigateTo(item?.alias ? item.alias : item.link)
    }
    currentProvider.value = allObject.value
  }
  const fetchListGameSort = async (params: { type?: string } = {}, $state?: any) => {
    loading()
    const _query = { ...route.query }
    const gameType = route?.params?.type
    const partnerProvider = typeof _query?.provider === 'string' ? _query.provider.toLowerCase() : ''
    const sortItem = getItemBySort(_query.sort as string)
    const sort = params?.type || (sortItem ? sortItem.type : 'default')
    let queryUrl = ''
    const type = route?.params?.game || route?.params?.type
    queryUrl = queryString.stringify({
      path_alias: gameType,
      provider_code: partnerProvider && partnerProvider !== 'all' ? partnerProvider : '',
      sort_by: sort,
      limit,
      page: page.value
    })
    try {
      const { data: response } = await fetchGames(queryUrl)
      if (response.status === 'OK') {
        if (page.value > 1) {
          listGame.value = listGame.value.concat(response.data?.items)
        } else {
          listGame.value = response.data?.items
        }
        totalItem.value = response.data?.total || 0
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        isShowSort.value = true
        store.setGameLists(listGame.value)
      }
      if (response.data.items?.length < limit && $state) {
        $state?.complete()
      } else if ($state) {
        $state?.loaded()
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    } finally {
      load()
    }
  }

  const fetchListGameJackpot = async () => {
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_GAME}?${'limit=25&path_alias=no-hu'}`)
      if (response.status === 'OK') {
        listGame.value = response.data?.items
        totalItem.value = response.data?.total || 0
        totalItemPerPage.value = limit * (page.value - 1) + response.data?.items.length
        isShowSort.value = true
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
  }

  const changePagination = ($state?: any) => {
    page.value++
    loadMoreLoading.value = false
    if (route.query.sort) {
      fetchListGameSort({}, $state)
    } else {
      fetchListGame($state)
    }
  }

  const scollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const calculatePlaceholder = () => {
    const itemsCount = listGame.value.length
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 5
    } else {
      itemsPerRow = 2
    }

    const remainder = itemsCount % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
    store.setPlaceholderCount(placeholderCount.value)
  }
  const fetchCongGame = async () => {
    loading()
    try {
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=game`)
      if (response.status === 'OK') {
        listCongGame.value = response?.data || []
        store.setProviderGames(response.data)
        filterProvider(listCongGame.value)
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
    load()
  }
  onMounted(() => {
    calculatePlaceholder()
  })

  watch(listGame, () => {
    calculatePlaceholder()
  })

  return {
    scollToTop,
    handleFilterProvider,
    initData,
    changePagination,
    listProvider,
    currentProvider,
    isShowCategoryProvider,
    listGame,
    isLoading,
    totalItem,
    totalItemPerPage,
    loadMoreLoading,
    EMPTY_CONTENT,
    SORT,
    currentSort,
    handleSort,
    isShowSort,
    TITLE_GAME,
    calculatePlaceholder,
    currentGameType,
    placeholderCount,
    fetchListGameJackpot,
    getBackgroundFromPath,
    fetchCongGame,
    listCongGame,
    fetchGames,
    handleChangeCategoryMenu
  }
}
